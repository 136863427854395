import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { SharedServiceService } from "../shared/shared-service.service";
import { CommonModule } from "@angular/common";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs";
import { LazyLoadDirective } from "../shared/lazy-load.directive";
import { SukothaiAnimations } from "../animation";

@Component({
  selector: "app-gift-card",
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule,LazyLoadDirective],
  templateUrl: "./gift-card.component.html",
  styleUrl: "./gift-card.component.scss",
  animations: SukothaiAnimations
})
export class GiftCardComponent implements OnInit {
  giftCard: any;
  giftCardForm!: FormGroup;
  filteredGiftCard: any[] = [];
  originalGiftCard: any[] = [];
  selectedFilter: string = "all"; // Default filter
  totalAmount: number = 0;
  initialTotalAmount: number = 0; // Track the initial total amount before any discounts
  dealCode: string = "";
  // gifterName: string = "";
  // gifterEmail: string = "";
  // gifterMobile: string = "";
  giftingMyself: boolean = false;
  selectedGiftType: string = ""; //
  usedDealCodes: Set<string> = new Set(); // Track used deal codes
  numberOfGiftsOptions: number[] = [];
  selectedGiftCardDetails: any[] = [];
  isTermsAccepted = false; // To track the checkbox state
  showTermsError = false; // To control the error message visibility

  termsAccepted: boolean = false; // Add this variable
  termsError: boolean = false;
  constructor(
    private sharedService: SharedServiceService,
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getGiftCard();
    this.initForm();
    this.generateGiftOptions();
    // this.addGiftCard();
  }

  getGiftCard() {
    
    this.sharedService.getGiftCard().subscribe({
      next: (res) => {
        this.cdr.markForCheck();
        this.giftCard = res.data;
        this.filteredGiftCard = [...this.giftCard]; // Set initial data
        this.originalGiftCard = [res.data]; // Make a copy of the original data
        // this.filteredGiftCard = [...this.giftCard];  // Initialize filteredGiftCard with original data
        this.calculateTotalAmount(); // Calculate the initial total amount
        this.initialTotalAmount = this.totalAmount; // Store the initial total amount for future reference
        // console.log(this.giftCard, "giftCard");
      },
      error: (err) => {
        console.error("Error fetching gift cards:", err);
      },
      complete: () => {
        // console.log("Gift card fetch completed.");
      },
    });
  }

  // Function to convert "HH:MM" format to minutes
  // convertToMinutes(time: string): number {
  //   const [hours, minutes] = time.split(':').map(Number);
  //   return hours * 60 + minutes;
  // }
  convertToMinutesABC(time: any): number {
    if (typeof time === "string") {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    } else if (typeof time === "number") {
      // If it's already a number, return it directly
      return time;
    } else {
      // If time is null, undefined, or in an unexpected format, return 0 or handle it appropriately
      console.warn("Invalid time format:", time);
      return 0;
    }
  }

  // Function to handle filtering logic
  filterData(): void {    
    switch (this.selectedFilter) {
      case "priceLowHigh":
        this.filteredGiftCard = this.giftCard.sort(
          (a: { F22_TAMT1: number }, b: { F22_TAMT1: number }) =>
            a.F22_TAMT1 - b.F22_TAMT1
        );
        break;
      case "priceHighLow":
        this.filteredGiftCard = this.giftCard.sort(
          (a: { F22_TAMT1: number }, b: { F22_TAMT1: number }) =>
            b.F22_TAMT1 - a.F22_TAMT1
        );
        break;
      case "timeLowHigh":
        this.filteredGiftCard = this.giftCard.sort(
          (a: { F22_HHMM: string }, b: { F22_HHMM: string }) =>
            this.convertToMinutesABC(a.F22_HHMM) -
            this.convertToMinutesABC(b.F22_HHMM)
        );
        break;
      case "timeHighLow":
        this.filteredGiftCard = this.giftCard.sort(
          (a: { F22_HHMM: string }, b: { F22_HHMM: string }) =>
            this.convertToMinutesABC(b.F22_HHMM) -
            this.convertToMinutesABC(a.F22_HHMM)
        );
        break;
        case "all":
          default:
            // Reset to the original, unfiltered data
            this.filteredGiftCard = this.giftCard // Use the spread operator to make a fresh copy
            break;
    }
  }

  onQtyChange(selectedGiftCard: any) {
    if (selectedGiftCard.isChecked) {
      // Add selected therapy to details
      this.selectedGiftCardDetails.push(selectedGiftCard);
      this.giftCardForm.controls["therapies"].setValue(
        this.selectedGiftCardDetails
      );
    } else {
      // Remove therapy from selected details if unchecked
      this.selectedGiftCardDetails = this.selectedGiftCardDetails.filter(
        (card) => card !== selectedGiftCard
      );
      this.giftCardForm.controls["therapies"].setValue(
        this.selectedGiftCardDetails
      );
    }

    // Recalculate total amount based on selected items
    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    this.totalAmount = this.giftCard
      .filter((item: { isChecked: any }) => item.isChecked) // Only include items where qty checkbox is checked
      .reduce((acc: any, item: { F22_TAMT1: any }) => acc + item.F22_TAMT1, 0);

    if (this.initialTotalAmount === 0) {
      // Set initial total amount only once
      this.initialTotalAmount = this.totalAmount;
    }
    // console.log("Total Amount:", this.totalAmount);
  }

  anyQtyChecked() {
    return this.giftCard?.some((item: { isChecked: any }) => item.isChecked);
  }

  recalculateTotal() {
    // console.log("Re-calculating total with deal code:", this.dealCode);

    // Check if deal code is provided, else set code as an empty string
    if (!this.dealCode) {
      this.giftCardForm.patchValue({ code: "" }); // Set code as empty string
      this.toastrService.warning("Please enter a deal code.", "Warning");
      return;
    } else {
      this.giftCardForm.patchValue({ code: this.dealCode }); // Set code with the entered deal code
    }

    // Check if the deal code has already been used
    if (this.usedDealCodes.has(this.dealCode)) {
      this.toastrService.warning(
        "This deal code has already been used.",
        "Warning"
      );
      return;
    }

    // Reset the total amount to the initial amount before applying a new discount
    this.totalAmount = this.giftCard
      .filter((item: { isChecked: any }) => item.isChecked) // Only include items where qty checkbox is checked
      .reduce((acc: any, item: { F22_TAMT1: any }) => acc + item.F22_TAMT1, 0);

    // Call the API to get the deal details
    this.sharedService
      .getDealCode(this.dealCode)
      .pipe(
        // Process the API response
        map((res) => {
          // console.log("API response:", res);

          if (res && res?.data?.F_DEALFLG && res?.data?.F_DEALAMT) {
            const dealFlag = res?.data?.F_DEALFLG;
            const dealAmount = parseFloat(res?.data?.F_DEALAMT); // Ensure it's a number

            // Check if the deal is a percentage or a lump sum
            if (dealFlag === "Percent") {
              // Apply percentage discount
              const discountAmount = (this.totalAmount * dealAmount) / 100;
              const discountedTotal = this.totalAmount - discountAmount;
              // console.log(
              //   `Discount: ${discountAmount} (Percent), New Total: ${discountedTotal}`
              // );
              this.totalAmount = discountedTotal;
            } else if (dealFlag === "Lumsum") {
              // Apply lump sum discount
              const discountedTotal = this.totalAmount - dealAmount;
              // console.log(
              //   `Discount: ${dealAmount} (Lumsum), New Total: ${discountedTotal}`
              // );
              this.totalAmount = discountedTotal;
            } else {
              this.toastrService.error("Invalid deal type.", "Error");
            }

            // Mark this deal code as used by adding it to the Set
            this.usedDealCodes.add(this.dealCode);
          } else {
            this.toastrService.error(
              "Invalid deal code or no discount available.",
              "Error"
            );
          }

          return res; // Pass the response to the next method
        })
      )
      .subscribe({
        next: (res) => {
          // console.log("Next method:", res);
          // Perform further actions here after the deal code is processed
          if (res.success) {
            this.toastrService.success("Deal applied successfully!", "Success");
          }
        },
        error: (error) => {
          console.error("Error fetching deal code:", error);
          this.toastrService.error(
            "There was an error fetching the deal. Please try again.",
            "Error"
          );
        },
      });
  }

  initForm() {
    this.giftCardForm = this._fb.group({
      name: ["", Validators.required],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
        ],
      ],
      mobileNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
          ),
        ],
      ],
      therapies: [[], Validators.required],
      noOfCards: this._fb.array([]), // Create an empty FormArray
      giftType: ["", Validators.required],
      quantity: ["1", Validators.required],
      code: [""],
      giftingMyself: [false],
    });
    this.giftCardForm
      .get("giftingMyself")
      ?.valueChanges.subscribe((isChecked) => {
        this.onGiftingMyselfChange(isChecked);
      });

    this.giftCardForm.get("quantity")?.valueChanges.subscribe((quantity) => {
      this.updateGiftEntries(quantity);
    });
  }

  get giftEntries(): FormArray {
    return this.giftCardForm.get("noOfCards") as FormArray;
  }
  createGiftEntry(): FormGroup {
    return this._fb.group({
      name: ["", Validators.required],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
        ],
      ],
      mobileNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
          ),
        ],
      ],
    });
  }
  updateGiftEntries(quantity: number): void {
    const currentNumber = this.giftEntries.length;

    if (quantity > currentNumber) {
      for (let i = currentNumber; i < quantity; i++) {
        this.giftEntries.push(this.createGiftEntry());
      }
    } else {
      for (let i = currentNumber; i > quantity; i--) {
        this.giftEntries.removeAt(i - 1);
      }
    }
  }
  // Method to patch the value

  checkError(
    index: number | string,
    controlName: string,
    errorName: string
  ): boolean {
    if (typeof index === "number") {
      // Checking for the formArray case
      const control = this.giftEntries.at(index)?.get(controlName);
      return (control?.hasError(errorName) && control.touched) || false;
    } else {
      // Checking for the main form controls (e.g., name, email, mobile)
      const control = this.giftCardForm.get(controlName);
      return (control?.hasError(errorName) && control.touched) || false;
    }
  }

  onGiftingMyselfChange(isChecked: boolean) {
    if (isChecked) {
      // Get values from the main form
      const name = this.giftCardForm.get("name")?.value;
      const email = this.giftCardForm.get("email")?.value;
      const mobileNumber = this.giftCardForm.get("mobileNumber")?.value;

      // Ensure the first entry exists in the FormArray
      if (this.giftEntries.length === 0) {
        this.giftEntries.push(this.createGiftEntry());
      }

      // Patch the main form data into the first entry of `noOfCards`
      this.giftEntries.at(0).patchValue({
        name: name,
        email: email,
        mobileNumber: mobileNumber,
      });
    } else {
      // Reset the first entry if "Gifting Myself" is unchecked
      if (this.giftEntries.length > 0) {
        this.giftEntries.at(0).reset();
      }
    }
  }


  onSubmit() {
    
    // Check if the form is valid
    this.termsError = false;
    if (!this.giftCardForm.valid || !this.termsAccepted) {
      this.giftCardForm.markAllAsTouched();
      this.termsError = !this.termsAccepted; // Set termsError to true if terms are not accepted

      // Check both conditions and create a combined error message if necessary
      let errorMessage = "";
      if (!this.giftCardForm.valid && !this.termsAccepted) {
        errorMessage =
          "Please fill out all fields and agree to the terms !";
      } else if (!this.giftCardForm.valid) {
        errorMessage =
          "Complete all fields to proceed with your gift card !";
      } else if (!this.termsAccepted) {
        errorMessage = "Please accept the terms and conditions !";
      }

      this.toastrService.warning(errorMessage, "Warning!");
      return;
    }

    const formData = {
      ...this.giftCardForm.value,
      totalAmount: this.totalAmount,
    };

    this.sharedService.insertForm(formData).subscribe({
      next: (res) => {
        if (res.success === true) {
          // console.log(res.data);
          this.giftCardForm.reset();
          // this.termsAccepted = false; // Reset termsAccepted
          this.termsAccepted = false; // Reset termsAccepted
          this.termsError = false; // Reset error flag
          this.navigateToConfirmation(res?.data?.email, res?.data?.orderId);
          // this.toastrService.success('Processing ......', 'Success');
        } else {
          this.toastrService.warning(
            "Booking failed. Please try again.",
            "Warning"
          );
        }
      },
      error: (error) => {
        this.toastrService.error(
          "There was an error submitting the form",
          "Error"
        );
        console.error("Error:", error);
      },
    });
  }
  generateGiftOptions() {
    this.numberOfGiftsOptions = Array.from({ length: 10 }, (_, i) => i + 1); // Create an array [1, 2, 3, ..., 10]
    if (this.giftEntries.length === 0) {
      this.giftEntries.push(this.createGiftEntry());
    }
  }
  // onGiftTypeChange(event: any) {
  //   const selectedValue = event.target.value; // Get the selected value from event
  //   console.log("Selected Gift Type:", selectedValue);
  //   this.giftCardForm.patchValue({
  //     giftType: selectedValue,
  //   });
  // }
  navigateToConfirmation(email: string, orderId: string) {
    this.router.navigate(["/buy-gift-cards/confirmation"], {
      queryParams: { email, orderId },
    });
  }
  onTermsChange(accepted: boolean) {
    this.termsAccepted = accepted;

    // If the terms are accepted, hide the error message
    if (this.termsAccepted) {
      this.termsError = false;
    } else {
      // If terms are not accepted, show the error message
      this.termsError = true;
    }
  }
}
