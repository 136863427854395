<div class="about-image-section" [@fadeInOut]="{value:'*',params:{delay:'5ms',x:'-50px'}}">
  <div class="background-img">
    <img appLazyLoad="../../assets/img/sukho-gift-card.jpg" alt="sukhothai" class="desk-banner">
    <img appLazyLoad="../../assets/img/buy-gift-card/Buy-Gift-Card.jpg" alt="sukhothai" class="mob-banner">

  </div>
  <div class="overlay-effect"></div>
</div>
<!-- <div class="about-image-section">
  <div class="background-img"></div>
  <div class="overlay-effect"></div>
</div> -->

<div class="gift-card-section" [@fadeInOut]="{value:'*',params:{delay:'5ms',x:'-50px'}}">
  <div class="container">
    <div class="gift-card-info">
      <div class="gift-card-info__heading section-padding">
        <h1 class="primary-title text-center">Buy Gift Cards</h1>
        <div class="mx-auto text-center">
          <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="Gift Cards" loading="lazy" class="mb-4">
        </div>
        <h2 class="secondary-title text-center">
          People visit us again & again & again. <br>
          Our most popular unique Gift Cards for someone you love.
        </h2>
        <div class="mt-3 text-center">
          <p>We guarantee a memorable experience for your loved ones with this gift card.</p>
          <p>
            A gift valued by everyone, no matter their taste and preferences. We offer corporate gift vouchers for
            companies, organizations, and individuals. Spa Therapy Gifts are the best solution as a business gift,
            incentive, or reward.
          </p>
          <p>
            Simply select a Spa Therapy from our wide options for gifting and make them feel happy. After selection, you
            can see the other transaction details on the right side to proceed.
          </p>
        </div>
      </div>
    </div>
    <div class="gift-card-info__details">

      <div class="gift-card-info__special">
        <div class="discount-text">*Special Online Discounted Price</div>
        <div class="gift-card-info__select-box">
          <select class="form-select gift-card-select" [(ngModel)]="selectedFilter" (change)="filterData()">
            <option value="all" selected>All</option>
            <option value="priceLowHigh">Price: Low to High</option>
            <option value="priceHighLow">Price: High to Low</option>
            <option value="timeLowHigh">Time: Low to High</option>
            <option value="timeHighLow">Time: High to Low</option>
          </select>
        </div>
        
      </div>

      <table class="table table-bordered">
        <thead class="table-light">
          <tr>
            <th scope="col" class="text-center">Code</th>
            <th scope="col">Therapy Type</th>
            <th scope="col" class="text-center">Therapists</th>
            <th scope="col" class="text-center">Minutes</th>
            <th scope="col">INR</th>
            <th scope="col" class="text-center">QTY</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of giftCard; let i = index">
            <td>{{ item.F22_TCODE }}</td>
            <td><h3 class="therapie-type">{{ item.F22_TNAME }}</h3></td>
            <td>{{ item.F22_TNOS }}</td>
            <td>{{ convertToMinutesABC(item.F22_HHMM )}} </td>
            <td>{{ item.F22_TAMT1 | currency:'INR':'symbol':'1.0'}}</td>
            <td>
              <input type="checkbox" [(ngModel)]="item.isChecked" (change)="onQtyChange(item)">
              <!-- <input 
              type="number" 
              [(ngModel)]="item.quantity" 
              [disabled]="!item.isChecked" 
              (input)="onQtyChange(i)" 
              min="1" 
              class="form-control mt-2"
            /> -->
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Total Payable and Deal Code Section -->
      <div class="deal-code" *ngIf="anyQtyChecked()">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>Total Payable in Indian Rupees</td>
              <td>₹ {{ totalAmount }}</td>
            </tr>
            <tr>
              <td>Please enter Deal/Corporate Code to avail your Special Pricing</td>
              <td>
                <form>
                  <div class="form-group">
                    <input type="text" [(ngModel)]="dealCode">
                  </div>
                  <button type="submit" class="btn btn-primary mt-2 view-all-btn"
                    (click)="recalculateTotal()">Re-Calculate</button>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <div class="container person-detail-form mt-5" *ngIf="anyQtyChecked()">
        <form [formGroup]="giftCardForm">
          <div class="row custom-detail">
            <div class="col-md-6">
              <h2 class="custom-detail__heading">Details of the person gifting the Card</h2>

              <!-- Name Field -->
              <div class="form-group --form-group">
                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                <div *ngIf="giftCardForm.controls['name'].invalid && giftCardForm.controls['name'].touched">
                  <span class="appointment__invalid-msg float-message"
                    *ngIf="giftCardForm.controls['name'].hasError('required')">
                    Please enter your name in the required field.
                  </span>
                </div>
              </div>

              <!-- Email Field -->
              <div class="form-group --form-group">
                <input type="email" class="form-control" placeholder="Email" formControlName="email">
                <div *ngIf="giftCardForm.controls['email'].invalid && giftCardForm.controls['email'].touched">
                  <span *ngIf="giftCardForm.controls['email'].hasError('required')" class="float-message">
                    Please enter your email address in the required field.
                  </span>
                  <span *ngIf="giftCardForm.controls['email'].hasError('pattern')" class="float-message">
                    Please enter a valid email.
                  </span>
                </div>
              </div>

              <!-- Mobile Number Field -->
              <div class="form-group --form-group">
                <input type="text" class="form-control" placeholder="Mobile" formControlName="mobileNumber">
                <div
                  *ngIf="giftCardForm.controls['mobileNumber'].invalid && giftCardForm.controls['mobileNumber'].touched">
                  <span *ngIf="giftCardForm.controls['mobileNumber'].hasError('required')" class="float-message">
                    Please enter your phone number in the required field.
                  </span>
                  <span *ngIf="giftCardForm.controls['mobileNumber'].hasError('pattern')" class="float-message">
                    Please enter a valid phone number.
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <h2 class="custom-detail__heading">Information of the person receiving the Card</h2>

              <!-- Gifting Myself Checkbox -->
              <div class="form-check custom-input-check --form-group">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="giftingMyself">
                <label class="form-check-label" for="exampleCheck1">Gifting Myself</label>
              </div>

              <!-- Gift Type Field -->
              <div class="form-group --form-group">
                <select class="form-select" aria-label="Default select example" formControlName="giftType">
                  <option selected value="" disabled>Select Your Gift Type</option>
                  <option value="A Gift for you">A Gift for you</option>
                  <option value="Diwali Gift">Diwali Gift</option>
                  <option value="Happy Anniversary">Happy Anniversary</option>
                  <option value="Happy Birthday">Happy Birthday</option>
                  <option value="Happy Couple">Happy Couple</option>
                  <option value="Love you">Love you</option>
                  <option value="Miss You">Miss You</option>
                  <option value="Seasons Greetings">Seasons Greetings</option>
                  <option value="Thank You">Thank You</option>
                </select>
                <div *ngIf="giftCardForm.controls['giftType'].invalid && giftCardForm.controls['giftType'].touched">
                  <span *ngIf="giftCardForm.controls['giftType'].hasError('required')" class="float-message">
                    Please select one.
                  </span>
                </div>
              </div>

              <!-- Quantity Field -->
              <div class="form-group --form-group">
                <select class="form-select" aria-label="Default select example" formControlName="quantity">
                  <option selected value="0" disabled>Select Number Of Gifts</option>
                  <option *ngFor="let num of numberOfGiftsOptions" [value]="num">{{ num }}</option>
                </select>
                <div *ngIf="giftCardForm.controls['quantity'].invalid && giftCardForm.controls['quantity'].touched">
                  <span *ngIf="giftCardForm.controls['quantity'].hasError('required')" class="float-message">
                    Please Select Number Of Gifts.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Dynamic Gift Card Entries -->
          <div class="row custom-row-details" formArrayName="noOfCards">
            <div *ngFor="let entry of giftEntries.controls; let i = index" [formGroupName]="i">
              <div class="row custom-row-details">
                <div class="col-md-4">
                  <input type="text" class="form-control" formControlName="name" placeholder="Name">
                  <div *ngIf="entry.get('name')?.invalid && entry.get('name')?.touched">
                    <div class="appointment__invalid-msg float-message" *ngIf="entry.get('name')?.hasError('required')">
                      Please enter your name in the required field.
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <input type="email" class="form-control" formControlName="email" placeholder="Email">
                  <div *ngIf="entry.get('email')?.invalid && entry.get('email')?.touched">
                    <div *ngIf="entry.get('email')?.hasError('required')" class="float-message">
                      Please enter your email address in the required field.
                    </div>
                    <div *ngIf="entry.get('email')?.hasError('pattern')" class="float-message">
                      Please enter a valid email.
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <input type="text" class="form-control" formControlName="mobileNumber" placeholder="Mobile">
                  <div *ngIf="entry.get('mobileNumber')?.invalid && entry.get('mobileNumber')?.touched">
                    <div *ngIf="entry.get('mobileNumber')?.hasError('required')" class="float-message">
                      Please enter your phone number in the required field.
                    </div>
                    <div *ngIf="entry.get('mobileNumber')?.hasError('pattern')" class="float-message">
                      Please enter a valid phone number.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <!-- Terms and Conditions -->
        <div class="form-group custom-term-cond mt-3">
          <div class="terms-cond">
            <input type="checkbox" [(ngModel)]="termsAccepted" (ngModelChange)="onTermsChange($event)" />
            <label class="form-label mb-0">&nbsp; I have read and agree to the <span class="term-services"><a
                  routerLink="/Terms-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a>
              </span> . Please accept them to proceed.</label>
          </div>
          <span class="text-danger" *ngIf="termsError">
            Please accept the terms and conditions.
          </span>

          <!-- <span class="term-services">
            <a href="https://d1zx74otwsbzif.cloudfront.net/myBucket/docList/Terms-Conditions.pdf" target="_blank">
              Terms of Service & Conditions
            </a>
          </span> -->
         
        </div>
        
        <!-- Submit Button -->
        <div class="row mt-5 mb-5">
          <div class="w-100 text-center">
            <button type="button" class="btn btn-primary view-all-btn" (click)="onSubmit()">
              Proceed for Card Payment
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>

<!-- Person Details Section -->

<div class="container mb-4" [@fadeInOut]="{value:'*',params:{delay:'5ms',x:'-50px'}}">
  <p class="text-center">When this gift card is presented at any of the SukhoThai spa it will carry the
    value you purchase
    now or it will be
    valued for Therapy selected. In case any less value is used by the gift card holder, then remaining value is carried
    forward on this card.</p>
  <p class="plsnote"><span class="text-center fw-bold">Please Note: </span>Spa Treatment will be provided subject to the
    Realisation of
    payment.
    Bank usually take 3 days from date
    of transaction to credit the amout to our account.</p>
</div>